body {
    height: 100%;
    margin: 0;
    padding: 0;
    color: black;
    font-size: 18px;
    font-family: "Tahoma";
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
}

input[type=number] {
    -moz-appearance: textfield;
}

.fieldInvalid {
    border: 2px solid #ff0000;
}

.fieldValid {
    border: 1px solid #b4b4b4;
}

input[type=text],
textarea {
    width: 100%;
    border-radius: 4px;
    padding: 0.5em;
    box-sizing: border-box;
}

input[type=password] {
    width: 100%;
    border-radius: 4px;
    padding: 0.5em;
    background-color: #fff;
    box-sizing: border-box;
}

input[type=date] {
    border-radius: 4px;
    padding: 0.5em;

    box-sizing: border-box;
}

input:focus {
    border-color: #aaa;
    box-shadow: 0 0 1px 3px rgba(59, 153, 252, .7);
    box-shadow: 0 0 0 3px -moz-mac-focusring;
    color: #222;
    outline: none;
}

select {
    width: 100%;
    display: block;
    font-size: 18px;
    line-height: 1.3;
    padding: 0.3em;
    max-width: 100%;
    box-sizing: border-box;
    margin-right: 0.8em;
    box-shadow: 0 1px 0 1px rgba(0, 0, 0, .04);
    border-radius: 4px;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: #f4f4f4;
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
        linear-gradient(to bottom, #ffffff 0%, #e5e5e5 100%);
    background-repeat: no-repeat, repeat;
    background-position: right .7em top 50%, 0 0;
    background-size: .65em auto, 100%;
}

select::-ms-expand {
    display: none;
}

select:hover {
    border-color: #888;
}

select:focus {
    border-color: #aaa;
    box-shadow: 0 0 1px 3px rgba(59, 153, 252, .7);
    box-shadow: 0 0 0 3px -moz-mac-focusring;
    color: #222;
    outline: none;
}

select option {
    font-weight: normal;
}

textarea {
    resize: none;
    background-color: white;
    margin-bottom: 10px;
}

hr {
    color: #227aaf;
    margin: 0;
    height: 3px;
    background-color: #227aaf;
}

.headerLogo {
    padding: 0 0 0 5px;
    width: 120px;
    height: 75px;
    background-image: url('../assets/logo.png');
    background-repeat: no-repeat;
    background-position: center 100%;
    background-size: 120px 75px;
}

.container {
    position: fixed;
    top: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.main {
    height: 100%;
    padding: 0 20px 0 20px;
    text-align: center;
    flex: 1;
    overflow: auto;
}

.chartPage {
    padding: 0 20px 0 20px;
    text-align: center;

}

.chartWrapper {
    margin-right: 0;
    flex: 1;
    overflow: auto;
}

.mainButton {
    height: auto;
    background-color: white;
    color: #000000;
    border: 1px solid #b4b4b4;
    border-radius: 4px;
    padding: 6px;
    opacity: 0.8;
}

.actionButton {
    margin-left: 10px;
    height: auto;
    background-color: #227aaf;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    padding: 6px;
    opacity: 0.8;
    cursor: pointer;
}

.exportButton {
    height: auto;
    background-color: white;
    color: #000000;
    border: 1px solid #b4b4b4;
    border-radius: 4px;
    padding: 6px;
    opacity: 0.8;
}

.content {

    /*flex: 1;*/
    /*overflow: auto;*/
}

.form {
    width: 640px;
    padding: 40px 20px 10px 20px;
    margin-top: 10px;
}


.header {
    height: auto;

}

.nav {
    padding-left: 30px;
}

.footer {
    height: 30px;
    padding-left: 30px;
    padding-top: 10px;
    color: white;
    text-align: center;
    font-size: 14px;
    background-color: #4399c8;
}


.headerTitle {
    color: white;
    padding: 20px;
    font-size: 30pt;
    text-align: center;
}

.headerSettings {
    padding: 15px;
    text-align: right;
    color: #000000;
}

.hrTop {
    padding-top: 50px;
}

.hrBottom {
    padding-bottom: 20px;
}

.spreadSht {
    background-color: white;
    color: black;
}


.contentBox {
    top: 100px;
    font-size: 60px;
    padding: 20px;
    font-weight: bold;
    text-align: center;
}

.page {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    /* height: 100%; */
}

.linkText {
    text-decoration: underline;
}

.required {
    padding-left: 0.3em;
    font-weight: bold;
    color: #d81c3f;
}

.page div {
    padding-bottom: 5px;
}

.formField {
    margin-right: 10px;
}

.form2ButtonGrid {
    display: grid;
    grid-template-columns: auto auto;
    margin-top: 40px;
    margin-bottom: 10px;
    grid-column-gap: 10px;
}

.form3ButtonGrid {
    display: grid;
    grid-template-columns: auto auto auto;
    margin-top: 40px;
    margin-bottom: 10px;
    grid-column-gap: 10px;
}


.initHeader {
    margin: 20px;
    text-align: center;
    font-weight: bold;
}

.initSection {
    margin-top: 20px;
    margin-bottom: 20px;
}

.initSectionHeader {
    text-align: center;
    font-weight: bold;
    color: #227aaf;
    font-size: 26px;
    margin-bottom: 20px;
}

.initDisclaimer {
    font-size: 13px;
}

.initQuestion {
    padding-left: 10px;
}

.linkButton {
    color: #00318b;
    background: none;
    border: none;
    margin: 0;
    padding: 0;
    font-size: 18px;
    cursor: pointer;
}

.formButton {
    background: #1D6E9F;
    color: white;
    border-radius: 10px;
    border: none;
    padding: 10px;
    font-size: 18px;
    cursor: pointer;
}

.plusButton {
    background: #7c7a7a;
    color: white;
    border-radius: 3px;
    border: none;
    padding: 1px 5px 1px 5px;
    font-size: 18px;
    width: 25px;
    height: 25px;
}

.minusButton {
    margin-left: 4px;
    background: #7c7a7a;
    color: white;
    border-radius: 3px;
    border: none;
    padding: 0 8px 3px 8px;
    font-size: 18px;
    width: 25px;
    height: 25px;
}

.exportSmall {
    background: #5299c4;
    color: white;
    border-radius: 3px;
    border: none;
    padding: 1px 1px 4px 1px;
    font-size: 18px;
    width: 24px;
    height: 20px;
}

.exportLarge {
    background: #5299c4;
    color: white;
    border-radius: 3px;
    border: none;
    padding: 1px 1px 4px 1px;
    font-size: 18px;
    width: 35px;
    height: 30px;
}

.formNumber {
    font-size: 18px;
    font-weight: bold;
}

.formHeader {
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 22px;
    font-weight: bold;
    padding: 10px;
}

.formHeader div {
    font-size: 22px;
}

.arrowLeft {
    padding-right: 10px;
    height: 13px;
}

.arrowRight {
    padding-left: 10px;
    height: 13px;
}


.formButtonInactive {
    background: #1D6E9F;
    color: white;
    border-radius: 10px;
    border: none;
    padding: 10px;
    font-size: 18px;
    opacity: .4;
}

.formShiftButtonInactive {
    background: #1D6E9F;
    color: white;
    border-radius: 10px;
    border: none;
    padding: 10px;
    font-size: 18px;
    opacity: 0;
}

.formNextButtonInactive {
    background: #1D6E9F;
    color: white;
    border-radius: 10px;
    border: none;
    padding: 10px;
    font-size: 18px;
    opacity: 0.4;
}

h1 {
    font: 24px sans-serif;
}

.bar {
    fill: #d81c3f;
}

.bar:hover {
    fill: darkgray;
}

.axis {
    font: 10px sans-serif;
}

.axis path,
.axis line {
    fill: none;
    stroke: #000;
    shape-rendering: crispEdges;
}

.x.axis path {
    display: none;
}

.radio {
    margin-top: 0.5rem;
}

.radio input[type="radio"] {
    position: absolute;
    opacity: 0;
}

.radio input[type="radio"]+.radio-label:before {
    content: '';
    background: #fff;
    border-radius: 100%;
    border: 1px solid #b4b4b4;
    display: inline-block;
    width: 1.4em;
    height: 1.4em;
    position: relative;
    top: -0.3em;
    margin-right: 0.5em;
    vertical-align: top;
    cursor: pointer;
    text-align: center;
    transition: all 250ms ease;
}

.radio input[type="radio"]:checked+.radio-label:before {
    background-color: #3197EE;
    box-shadow: inset 0 0 0 4px #f4f4f4;
}

.radio input[type="radio"]:focus+.radio-label:before {
    outline: none;
    border-color: #3197EE;
}

.radio input[type="radio"]:disabled+.radio-label:before {
    box-shadow: inset 0 0 0 4px #f4f4f4;
    border-color: #b4b4b4;
    background: #b4b4b4;
}

.radio input[type="radio"]+.radio-label:empty:before {
    margin-right: 0;
}

/* The container */
.checkbox {
    position: relative;
    padding-left: 22px;
    margin-top: 0.4rem;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 10px;
}

/* Hide the browser's default checkbox */
.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0.01em;
    left: 0;
    height: 15px;
    width: 15px;
    background-color: #fff;
    border: 1px solid #b4b4b4;
}


/* When the checkbox is checked, add a blue background */
.checkbox input:checked~.checkmark {
    background-color: #2196F3;
    transition: all 250ms ease;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.checkbox input:checked~.checkmark:after {
    display: block;

}

/* Style the checkmark/indicator */
.checkbox .checkmark:after {
    left: 4px;
    top: 1px;
    width: 5px;
    height: 8px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);

}

.headerClass {
    text-align: right;
}

.loading {
    position: absolute;
    left: calc(50% - 30px);
    top: 300px;
    margin-right: 30px;
}

.loader {
    position: absolute;
    left: 50%;
    top: 410px;
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #3498db;
    z-index: 1;
    width: 120px;
    height: 120px;
    margin: -76px 0 0 -76px;
    -webkit-animation: spin 2s linear infinite;
    /* Safari */
    animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.ag-theme-alpine .po {
    background-color: #c5c5c5 !important;
}

.ag-theme-alpine .Invoice {
    background-color: #e3e1e1 !important;
}

.ag-theme-alpine .Total {
    background-color: #c99ef3 !important;
}

.ag-theme-alpine .grandTotal {
    background-color: #5299c4 !important;
}

.ag-theme-alpine .subTotal {
    background-color: #ffffff !important;
}

.ag-theme-alpine .Year1 {
    background-color: #c6e0b5 !important;
}

.ag-theme-alpine .Col1 {
    background-color: #c6e0b4 !important;
}

.ag-theme-alpine .Year2 {
    background-color: #8ea9dc !important;
}

.ag-theme-alpine .Col2 {
    background-color: #8ea9db !important;
}

.ag-theme-alpine .Pending {
    background-color: #fcee7b !important;
}

.ag-theme-alpine .Outstanding {
    background-color: #fcee7a !important;
}

.ag-theme-alpine .SummaryBold {
    font-weight: bold;
}

.ag-theme-alpine .Regular {
    background-color: #ffffff !important;
}

.ag-theme-alpine .Rejected {
    background-color: #fb9268 !important;
}

.ag-theme-alpine .noP2 {
    background-color: #93dcfb !important;
}

.ag-theme-alpine .noQB {
    background-color: #fda8b5 !important;
}

.ag-theme-alpine .Exclude {
    background-color: #7c9471 !important;
}

.ag-theme-alpine .Void {
    background-color: #92f694 !important;
}

.ag-theme-alpine .Mismatch {
    background-color: #fc0516 !important;
}

.ag-theme-alpine .Ignored {
    background-color: #7a6567 !important;
}

.ag-cell,
.ag-theme-alpine .ag-full-width-row .ag-cell-wrapper.ag-row-group {
    Line-Height: 20px !important;
}

.rendererButton {
    height: 10px;
    width: 30px;
    margin-top: -3px;
    color: #266d9d;

}

/* .file-input-label {
    display: inline-block;
    padding: 8px 12px;
    background-color: #007bff;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
} */

.file-input-label input[type="file"] {
    opacity: 0;
    position: absolute;
    left: -9999px;
    /* Move the input element offscreen */
}

.custom-header-class {
    padding: 0;
    margin: 0;/* Custom padding */
    /* Add any other styles you want to apply to the header */
}
.ag-theme-alpine .with-divider {
    border-right: 2px solid #dee2e6;
}
.ag-header-group-cell-label {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
